#vanilla-reader__reader-view__audiobook-cover-container {
  width: 100%;
  height: 100%;
  display: flex;
}

#vanilla-reader__reader-view__audiobook-cover-container__image {
  width: 100%;
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin: auto;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

/*# sourceMappingURL=index.dc75d1e3.css.map */
