#vanilla-reader__reader-view__audiobook-cover-container {
    display: flex;
    width: 100%;
    height: 100%;
}

#vanilla-reader__reader-view__audiobook-cover-container__image {
    margin: auto;
    width: 100%;
    max-width: fit-content;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
